import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as metaApi from 'api/meta.api';
import * as metaInterface from 'interfaces/meta.interface';
import * as superadminApi from 'api/superAdmin.api';
import * as superAdminInterface from 'interfaces/superAdmin.interface';

// Map fields
export async function getMapFieldsAction({
  dispatch,
  token,
  translate,
  clientId,
}: {
  dispatch: React.Dispatch<any>;
  token: string;
  translate: any;
  clientId: string;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_GET_MAP_FIELDS,
    payload: true,
  });
  try {
    const getMapFieldsFetch = await superadminApi.getMapFieldsAPI({ token, clientId });
    if (
      getMapFieldsFetch.response.status === 200 ||
      getMapFieldsFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.GET_MAP_FIELDS,
        payload: getMapFieldsFetch.data.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener los campos del mapa');
    notificationErr(metaError.get_map_fields_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_GET_MAP_FIELDS,
      payload: false,
    });
  }
}

export async function createMapFieldAction({
  dispatch,
  fieldData,
  mapId,
  mappings,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  mapId?: string;
  mappings: metaInterface.IMap[];
  fieldData:
    | superAdminInterface.MapFieldReplaceKeyOrValueBodyProps
    | superAdminInterface.MapFieldSearchBodyProps
    | superAdminInterface.MapFieldSubstringBodyProps
    | superAdminInterface.MapFieldPatternBodyProps
    | superAdminInterface.MapFieldDateBodyProps
    | superAdminInterface.MapFieldLoopBodyProps
    | superAdminInterface.MapFieldDeleteBodyProps
    | superAdminInterface.MapFieldCleanBodyProps;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_CREATE_MAP_FIELD,
    payload: true,
  });
  try {
    const mapSelected = mappings.find((map) => map.id === mapId);
    if (mapSelected?.map_field_maps.length === 0) {
      fieldData.secuence = 0;
    }
    const createMapFieldFetch = await superadminApi.createMapFieldAPI({
      mapField: fieldData,
      token,
    });
    if (
      createMapFieldFetch.response.status === 200 ||
      createMapFieldFetch.response.status === 201
    ) {
      if (mapId) {
        try {
          const mapSelectedFieldsIdx = mappings
            .find((map) => map.id === mapId)
            ?.map_field_maps.map((mapField) => mapField.map_field_id);
          const addFieldToMap = await metaApi.addFieldToMapAPI({
            mapId,
            mapFieldsIdx:
              mapSelectedFieldsIdx && mapSelectedFieldsIdx.length > 0
                ? [...mapSelectedFieldsIdx, createMapFieldFetch.data.data[0].id]
                : [createMapFieldFetch.data.data[0].id],
            token,
          });
          if (
            addFieldToMap.response.status === 200 ||
            addFieldToMap.response.status === 201
          ) {
            dispatch({
              type: MetaTypes.ADD_FIELD_TO_MAP,
              payload: {
                mapId: mapId,
                mapField: {
                  ...addFieldToMap.data.data[0],
                  map_field: createMapFieldFetch.data.data[0],
                },
              },
            });
            msg.messageSuccess('Instrucción creada con éxito');
            return true;
          }
        } catch (error) {
          msg.messageError('Error del servidor al agregar el campo al mapa');
          notificationErr(metaError.add_field_to_map_001, translate);
          return false;
        }
      } else {
        msg.messageSuccess('Instrucción creada con éxito');
        dispatch({
          type: MetaTypes.CREATE_MAP_FIELD,
          payload: createMapFieldFetch.data.data[0],
        });
        return true;
      }
    }
    msg.messageError('Error al crear el campo');
    notificationErr(metaError.create_map_field_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al crear el campo');
    notificationErr(metaError.create_map_field_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_CREATE_MAP_FIELD,
      payload: false,
    });
  }
}

export async function updateMapFieldAction({
  dispatch,
  body,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: superAdminInterface.IupdateMapFieldBodyProps;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_UPDATE_MAP_FIELD,
    payload: true,
  });
  try {
    const updateMapFieldFetch = await superadminApi.updateMapFieldAPI({
      body: body,
      token,
    });
    if (
      updateMapFieldFetch.response.status === 200 ||
      updateMapFieldFetch.response.status === 201
    ) {
      msg.messageSuccess('Campo actualizado con éxito');
      dispatch({
        type: MetaTypes.UPDATE_MAP_FIELD,
        payload: {
          id: body.filter.id,
          data: body.update,
        },
      });
      return true;
    }
    msg.messageError('Error al actualizar el campo');
    notificationErr(metaError.update_map_field_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al actualizar el campo');
    notificationErr(metaError.update_map_field_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_UPDATE_MAP_FIELD,
      payload: false,
    });
  }
}

export async function deleteMapFieldAction({
  dispatch,
  mapFieldId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  mapFieldId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD,
    payload: {
      mapFieldId,
      loading: true,
    },
  });
  try {
    const deleteMapFieldFetch = await superadminApi.deleteMapFieldAPI({
      mapFieldId,
      token,
    });
    if (deleteMapFieldFetch.response.status === 409) {
      return msg.messageWarning('No se puede eliminar el campo porque está en uso');
    }
    if (
      deleteMapFieldFetch.response.status === 200 ||
      deleteMapFieldFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.DELETE_MAP_FIELD,
        payload: mapFieldId,
      });
      msg.messageSuccess('Campo eliminado con éxito');
      return true;
    }
    msg.messageError('Error al eliminar el campo');
    notificationErr(metaError.delete_map_field_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar el campo');
    notificationErr(metaError.delete_map_field_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD,
      payload: {
        mapFieldId: '',
        loading: false,
      },
    });
  }
}

export async function removeMapFieldFromMapAction({
  dispatch,
  mapId,
  mapFieldId,
  deleteMapField,
  mappings,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  mapId: string;
  mapFieldId: string;
  deleteMapField: boolean;
  mappings: metaInterface.IMap[];
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD,
    payload: {
      mapFieldId,
      loading: true,
    },
  });
  try {
    const mapSelectedFieldsIdx = mappings
      .find((map) => map.id === mapId)
      ?.map_field_maps.map((mapField) => mapField.map_field_id);
    const addFieldToMap = await metaApi.addFieldToMapAPI({
      mapId,
      mapFieldsIdx: mapSelectedFieldsIdx?.filter((field) => field !== mapFieldId) ?? [],
      token,
    });
    if (addFieldToMap.response.status === 200 || addFieldToMap.response.status === 201) {
      if (deleteMapField) {
        try {
          const deleteMapFieldFetch = await superadminApi.deleteMapFieldAPI({
            mapFieldId,
            token,
          });
          if (deleteMapFieldFetch.response.status === 409) {
            return msg.messageWarning('No se puede eliminar el campo porque está en uso');
          }
          if (
            deleteMapFieldFetch.response.status === 200 ||
            deleteMapFieldFetch.response.status === 201
          ) {
            dispatch({
              type: MetaTypes.REMOVE_FIELD_FROM_MAP,
              payload: {
                mapId: mapId,
                mapFieldId,
              },
            });
            dispatch({
              type: MetaTypes.DELETE_MAP_FIELD,
              payload: mapFieldId,
            });
            msg.messageSuccess('Campo eliminado con éxito');
            return true;
          }
          msg.messageError('Error al eliminar el campo');
          notificationErr(metaError.delete_map_field_002, translate);
          return false;
        } catch (error) {
          msg.messageError('Error del servidor al eliminar el campo');
          notificationErr(metaError.delete_map_field_001, translate);
          return false;
        } finally {
          dispatch({
            type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD,
            payload: {
              mapFieldId: '',
              loading: false,
            },
          });
        }
      } else {
        dispatch({
          type: MetaTypes.REMOVE_FIELD_FROM_MAP,
          payload: {
            mapId: mapId,
            mapFieldId,
          },
        });
        msg.messageSuccess('Instrucción creada con éxito');
        return true;
      }
    }
    msg.messageError('Error al eliminar la instrucción del mapa');
    notificationErr(metaError.remove_map_field_from_map_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar la instrucción del mapa');
    notificationErr(metaError.remove_map_field_from_map_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD,
      payload: {
        mapFieldId: '',
        loading: false,
      },
    });
  }
}

// Map fields templates ///////////////////////////////////////////////
export async function getMapFieldsTemplatesAction({
  dispatch,
  token,
  translate,
  clientId,
}: {
  dispatch: React.Dispatch<any>;
  token: string;
  translate: any;
  clientId: string;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES,
    payload: true,
  });
  try {
    const getMapFieldsFetch = await superadminApi.getMapFieldsTemplatesAPI({
      token,
      clientId,
    });
    if (
      getMapFieldsFetch.response.status === 200 ||
      getMapFieldsFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.GET_MAP_FIELDS_TEMPLATES,
        payload: Object.entries(getMapFieldsFetch.data.data).map(
          ([templateName, rest]) => {
            return {
              templateName,
              mapFields: rest.map((item) => item),
            };
          }
        ),
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener los campos del mapa');
    notificationErr(metaError.get_map_fields_templates_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES,
      payload: false,
    });
  }
}

export async function deleteMapFieldTemplateAction({
  dispatch,
  templateName,
  token,
  translate,
  clientId,
}: {
  dispatch: React.Dispatch<any>;
  templateName: string;
  token: string;
  translate: any;
  clientId: string;
}) {
  dispatch({
    type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD_TEMPLATE,
    payload: {
      templateName,
      loading: true,
    },
  });
  try {
    const deleteMapFieldTemplateFetch = await superadminApi.deleteMapFieldTemplateAPI({
      templateName,
      token,
      clientId,
    });
    if (deleteMapFieldTemplateFetch.response.status === 409) {
      return msg.messageWarning('No se puede eliminar el campo porque está en uso');
    }
    if (
      deleteMapFieldTemplateFetch.response.status === 200 ||
      deleteMapFieldTemplateFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.DELETE_MAP_FIELD_TEMPLATE,
        payload: templateName,
      });
      msg.messageSuccess('Plantilla eliminada con éxito');
      return true;
    }
    msg.messageError('Error al eliminar la plantilla');
    notificationErr(metaError.delete_map_field_template_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar la plantilla');
    notificationErr(metaError.delete_map_field_template_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.IS_LOADING_DELETE_MAP_FIELD_TEMPLATE,
      payload: {
        templateName: '',
        loading: false,
      },
    });
  }
}

export async function copyMapFieldsTemplateAction({
  dispatch,
  templateTag,
  token,
  translate,
  clientId,
}: {
  dispatch: React.Dispatch<any>;
  templateTag: string;
  token: string;
  translate: any;
  clientId: string;
}) {
  try {
    const copyMapFieldsTemplateFetch = await metaApi.copyMapFieldsTemplateAPI({
      name: templateTag,
      clientId,
      tag: templateTag,
      token,
    });
    if (
      copyMapFieldsTemplateFetch.response.status === 200 ||
      copyMapFieldsTemplateFetch.response.status === 201
    ) {
      dispatch({
        type: MetaTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES,
        payload: true,
      });
      try {
        const getMapFieldsFetch = await superadminApi.getMapFieldsTemplatesAPI({
          token,
          clientId,
        });
        if (
          getMapFieldsFetch.response.status === 200 ||
          getMapFieldsFetch.response.status === 201
        ) {
          dispatch({
            type: MetaTypes.GET_MAP_FIELDS_TEMPLATES,
            payload: Object.entries(getMapFieldsFetch.data.data).map(
              ([templateName, rest]) => {
                return {
                  templateName,
                  mapFields: rest.map((item) => item),
                };
              }
            ),
          });

          try {
            const getMapFieldsFetch = await superadminApi.getMapFieldsAPI({
              token,
              clientId,
            });
            if (
              getMapFieldsFetch.response.status === 200 ||
              getMapFieldsFetch.response.status === 201
            ) {
              dispatch({
                type: MetaTypes.GET_MAP_FIELDS,
                payload: getMapFieldsFetch.data.data,
              });
              msg.messageSuccess('Plantilla copiada con éxito');
              return true;
            }
            return false;
          } catch (error) {
            msg.messageError('Error del servidor al obtener los campos del mapa');
            notificationErr(metaError.get_map_fields_001, translate);
            return false;
          } finally {
            dispatch({
              type: MetaTypes.IS_LOADING_GET_MAP_FIELDS,
              payload: false,
            });
          }
        }
        return false;
      } catch (error) {
        msg.messageError('Error del servidor al obtener los campos del mapa');
        notificationErr(metaError.get_map_fields_templates_001, translate);
        return false;
      } finally {
        dispatch({
          type: MetaTypes.IS_LOADING_GET_MAP_FIELDS_TEMPLATES,
          payload: false,
        });
      }
    }
    if (copyMapFieldsTemplateFetch.response.status === 405) {
      msg.messageWarning('Ya existe una plantilla con ese nombre');
      return false;
    }
    msg.messageError('Error al copiar la plantilla');
    notificationErr(metaError.copy_map_fields_template_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al copiar la plantilla');
    notificationErr(metaError.copy_map_fields_template_001, translate);
    return false;
  }
}
