import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';
import { basePathSftpService, dataBasePath } from './utils/config';

export const getConnectionsAPI = async ({
  body,
  token,
}: metaResponseInterface.TgetConnectorsAPIProps): Promise<metaResponseInterface.TgetConnectorsAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/connectors/search`, params);
  const data = await response.json();
  return { response, data };
};

// MAPS ///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export const getMapsAPI = async ({
  clientId,
  token,
}: metaResponseInterface.TgetMapsAPIProps): Promise<metaResponseInterface.TgetMapsAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/get_maps`, params);
  const data = await response.json();
  return { response, data };
};

export const createMapAPI = async ({
  body,
  token,
}: metaResponseInterface.TcreateMapAPIProps): Promise<metaResponseInterface.TcreateMapAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/mapping/create_map`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteMapAPI = async ({
  mapId,
  token,
}: {
  mapId: string;
  token: string;
}): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${dataBasePath}/mapping/delete_map?id=${mapId}`, params);
  const data = await response.json();
  return { response, data };
};

export const updateMapAPI = async ({
  body,
  token,
}: metaResponseInterface.TupdateMapAPIProps): Promise<metaResponseInterface.TupdateMapAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/mapping/update_map`, params);
  const data = await response.json();
  return { response, data };
};

export const addMapToConnectorAPI = async ({
  connectorId,
  maps,
  token,
}: {
  connectorId: string;
  maps: string[];
  token: string;
}): Promise<metaResponseInterface.TaddMapToConnectorAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      connector_id: connectorId,
      maps: maps,
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/add_map_to_connector`, params);
  const data = await response.json();
  return { response, data };
};

export const addFieldToMapAPI = async ({
  mapId,
  mapFieldsIdx,
  token,
}: {
  mapId: string;
  mapFieldsIdx: string[];
  token: string;
}): Promise<{
  response: Response;
  data: {
    count: number;
    data: [
      {
        createdAt: string;
        updatedAt: string;
        id: string;
        map_id: string;
        map_field_id: string;
      },
    ];
    delete_items: number;
  };
}> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      map_id: mapId,
      map_fields: mapFieldsIdx,
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/add_field_to_map`, params);
  const data = await response.json();
  return { response, data };
};
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

// MAP FIELDS /////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export const copyMapFieldsTemplateAPI = async ({
  name,
  clientId,
  tag,
  token,
}: {
  name: string;
  clientId: string;
  tag: string;
  token: string;
}): Promise<metaResponseInterface.TcopyMapFieldsTemplateAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      client_id: clientId,
      tag,
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/copy_map_field_template`, params);
  const data = await response.json();
  return { response, data };
};
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////

// SFTP ///////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
// export const getSftpUserAPI = async ({
//   // SFTP ///////////////////////////////////////////////////////////////////////////
//   //////////////////////////////////////////////////////////////////////////////////////
//   body,
//   token,
// }: TgetSftpUserAPIProps): Promise<TgetSftpUserAPIResponse> => {
//   const params = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${token}`,
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(body),
//   };
//   const response = await fetch(`${basePath}/sftp/get_users`, params);
//   const data = await response.json();
//   return { response, data };
// };

export const createSftpUserAPI = async ({
  body,
  token,
}: metaResponseInterface.TcreateSftpUserAPIProps): Promise<metaResponseInterface.TcreateSftpUserAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${basePathSftpService}/cli/create_sftp_user`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteSftpUserAPI = async ({
  user_name,
  token,
}: metaResponseInterface.TdeleteSftpUserAPIProps): Promise<metaResponseInterface.TdeleteSftpUserAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${basePathSftpService}/cli/delete_sftp_user?user_name=${user_name}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////

// Shopify ///////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
export const createShopifyConnectionAPI = async ({
  body,
  token,
}: metaResponseInterface.TcreateShopifyConnectionAPIProps): Promise<metaResponseInterface.TcreateShopifyConnectionAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/create_connector`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateShopifyConnectionAPI = async ({
  body,
  connectionId,
  token,
}: metaResponseInterface.TupdateShopifyConnectionAPIProps): Promise<metaResponseInterface.TupdateShopifyConnectionAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: {
        id: connectionId,
      },
      update: body,
    }),
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/update_connector`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const removeShopifyConnectionAPI = async ({
  connectionId,
  token,
}: metaResponseInterface.TremoveShopifyConnectionAPIProps): Promise<metaResponseInterface.TremoveShopifyConnectionAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/delete_connector?id=${connectionId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const testShopifyConnectionAPI = async ({
  token,
  connectorId,
}: {
  token: string;
  connectorId: string;
}): Promise<metaResponseInterface.ItestShopifyConnectionAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/test_connection?id=${connectorId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// Configuration Shopify
export const getShopifyConnectorConfigurationAPI = async ({
  connectorId,
  token,
}: {
  connectorId: string;
  token: string;
}): Promise<metaResponseInterface.IgetShopifyConnectorConfigurationAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/get_configuration?connector_id=${connectorId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createUpdateShopifyConfigurationAPI = async ({
  connector_id,
  queries,
  token,
}: metaResponseInterface.TcreateUpdateShopifyConfigurationAPIProps): Promise<metaResponseInterface.TcreateUpdateShopifyConfigurationAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      connector_id: connector_id,
      queries: queries,
    }),
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/add_configuration`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// Queries templates shopify
export const copyShopifyTemplateAPI = async ({
  body,
  token,
}: metaResponseInterface.TcopyShopifyTemplateAPIProps): Promise<metaResponseInterface.TcopyShopifyTemplateAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/copy_template`, params);
  const data = await response.json();
  return { response, data };
};

// Test map
export const testMapAPI = async ({
  body,
  token,
}: metaResponseInterface.ItestMapAPIProps): Promise<metaResponseInterface.TtestMapAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/mapping/test_map_fields`, params);
  const data = await response.json();
  return { response, data };
};
//////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////
