import { IInitialSuperAdminState } from 'interfaces/superAdmin.interface';
import * as SuperAdminType from './superAdmin.types';

export default function superAdminReducer(state: IInitialSuperAdminState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SuperAdminType.SET_CLIENTS: {
      const setClients: IInitialSuperAdminState = {
        ...state,
        wholesaleClients: payload.wholesale,
        brandClients: payload.brand,
      };
      return setClients;
    }

    case SuperAdminType.SET_CONNECTORS: {
      const setConnectors: IInitialSuperAdminState = {
        ...state,
        connectors: payload,
      };
      return setConnectors;
    }

    case SuperAdminType.SET_IS_LOADING_CONNECTORS: {
      const setIsLoadingConnectors: IInitialSuperAdminState = {
        ...state,
        isLoadingConnectors: payload,
      };
      return setIsLoadingConnectors;
    }

    // Map fields
    case SuperAdminType.GET_MAP_FIELDS: {
      const getMapField: IInitialSuperAdminState = {
        ...state,
        mapFields: payload,
      };
      return getMapField;
    }

    case SuperAdminType.CREATE_MAP_FIELD: {
      const createMapField: IInitialSuperAdminState = {
        ...state,
        mapFields: [payload, ...state.mapFields],
      };
      return createMapField;
    }

    case SuperAdminType.UPDATE_MAP_FIELD: {
      const updateMapField: IInitialSuperAdminState = {
        ...state,
        mapFields: state.mapFields.map((field) =>
          field.id === payload.id ? { ...field, ...payload.data } : field
        ),
      };
      return updateMapField;
    }

    case SuperAdminType.DELETE_MAP_FIELD: {
      const deleteMapField: IInitialSuperAdminState = {
        ...state,
        mapFields: state.mapFields.filter((field) => field.id !== payload),
      };
      return deleteMapField;
    }

    case SuperAdminType.IS_LOADING_GET_MAP_FIELDS: {
      const isLoadingGetMapFields: IInitialSuperAdminState = {
        ...state,
        isLoadingMapFields: payload,
      };
      return isLoadingGetMapFields;
    }

    case SuperAdminType.IS_LOADING_CREATE_MAP_FIELD: {
      const isLoadingCreateMapField: IInitialSuperAdminState = {
        ...state,
        isLoadingCreateMapField: payload,
      };
      return isLoadingCreateMapField;
    }

    case SuperAdminType.IS_LOADING_UPDATE_MAP_FIELD: {
      const isLoadingUpdateMapField: IInitialSuperAdminState = {
        ...state,
        isLoadingUpdateMapField: payload,
      };
      return isLoadingUpdateMapField;
    }

    case SuperAdminType.IS_LOADING_DELETE_MAP_FIELD: {
      const isLoadingDeleteMapField: IInitialSuperAdminState = {
        ...state,
        isLoadingDeleteMapField: {
          loading: payload.loading,
          mapFieldId: payload.mapFieldId,
        },
      };
      return isLoadingDeleteMapField;
    }

    // Map fields templates //////////////////////////////////////////
    case SuperAdminType.GET_MAP_FIELDS_TEMPLATES: {
      const getMapFieldsTemplates: IInitialSuperAdminState = {
        ...state,
        mapFieldsTemplates: payload,
      };
      return getMapFieldsTemplates;
    }

    case SuperAdminType.IS_LOADING_GET_MAP_FIELDS_TEMPLATES: {
      const isLoadingGetMapFieldsTemplates: IInitialSuperAdminState = {
        ...state,
        isLoadingMapFieldsTemplates: payload,
      };
      return isLoadingGetMapFieldsTemplates;
    }

    case SuperAdminType.CREATE_MAP_FIELDS_TEMPLATE: {
      const createMapFieldsTemplate: IInitialSuperAdminState = {
        ...state,
        mapFieldsTemplates: [payload, ...state.mapFieldsTemplates],
      };
      return createMapFieldsTemplate;
    }

    case SuperAdminType.IS_LOADING_CREATE_MAP_FIELDS_TEMPLATE: {
      const isLoadingCreateMapFieldsTemplate: IInitialSuperAdminState = {
        ...state,
        isLoadingCreateMapFieldsTemplate: payload,
      };
      return isLoadingCreateMapFieldsTemplate;
    }

    case SuperAdminType.DELETE_MAP_FIELD_TEMPLATE: {
      const deleteMapFieldTemplate: IInitialSuperAdminState = {
        ...state,
        mapFieldsTemplates: state.mapFieldsTemplates.filter(
          (item) => item.templateName !== payload
        ),
      };
      return deleteMapFieldTemplate;
    }

    case SuperAdminType.IS_LOADING_DELETE_MAP_FIELD_TEMPLATE: {
      const isLoadingDeleteMapFieldTemplate: IInitialSuperAdminState = {
        ...state,
        isLoadingDeleteMapFieldTemplate: {
          loading: payload.loading,
          templateName: payload.templateName,
        },
      };
      return isLoadingDeleteMapFieldTemplate;
    }

    // Queries ///////////////////////////////////////////////////////
    case SuperAdminType.SET_QUERIES: {
      const setQueries: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: payload,
        },
      };
      return setQueries;
    }
    case SuperAdminType.SET_IS_LOADING_QUERIES: {
      const setIsLoadingQueries: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingQueries: payload,
        },
      };
      return setIsLoadingQueries;
    }
    case SuperAdminType.SET_QUERY_SELECTED: {
      const setQuerySelected: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          querySelected: payload,
        },
      };
      return setQuerySelected;
    }
    case SuperAdminType.ADD_NEW_QUERY: {
      const addNewQuery: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: [payload, ...state.shopify.queries],
        },
      };
      return addNewQuery;
    }
    case SuperAdminType.SET_IS_LOADING_CREATE_QUERY: {
      const setIsLoadingCreateQuery: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingCreateQuery: payload,
        },
      };
      return setIsLoadingCreateQuery;
    }
    case SuperAdminType.DELETE_QUERY: {
      const deleteQuery: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: state.shopify.queries.filter((item) => item.id !== payload),
        },
      };
      return deleteQuery;
    }
    case SuperAdminType.SET_IS_LOADING_DELETE_QUERY: {
      const setIsLoadingDeleteQuery: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingDeleteQuery: {
            loading: payload.loading,
            queryId: payload.queryId,
          },
        },
      };
      return setIsLoadingDeleteQuery;
    }
    case SuperAdminType.UPDATE_QUERY: {
      const updateQuery: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: state.shopify.queries.map((item) =>
            item.id === payload.id ? { ...item, ...payload.data } : item
          ),
        },
      };
      return updateQuery;
    }
    case SuperAdminType.SET_IS_LOADING_UPDATE_QUERY: {
      const setIsLoadingUpdateQuery: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingUpdateQuery: payload,
        },
      };
      return setIsLoadingUpdateQuery;
    }

    // Templates /////////////////////////////////////////////////////
    case SuperAdminType.SET_TEMPLATES: {
      const setTemplates: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          templates: payload,
        },
      };
      return setTemplates;
    }

    case SuperAdminType.SET_IS_LOADING_TEMPLATES: {
      const setIsLoadingTemplates: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingTemplates: payload,
        },
      };
      return setIsLoadingTemplates;
    }

    case SuperAdminType.SET_TEMPLATE_SELECTED: {
      const setTemplateSelected: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          templateSelected: payload,
        },
      };
      return setTemplateSelected;
    }

    case SuperAdminType.CREATE_UPDATE_TEMPLATE: {
      const createUpdateTemplate: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          templates: !state.shopify.templates.find((item) => item.tag === payload.tag)
            ? [payload.data, ...state.shopify.templates]
            : state.shopify.templates.map((item) =>
                item.tag === payload.tag ? { ...item, ...payload.data } : item
              ),
        },
      };
      return createUpdateTemplate;
    }

    case SuperAdminType.SET_IS_LOADING_CREATE_UPDATE_TEMPLATE: {
      const setIsLoadingCreateUpdateTemplate: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingCreateUpdateTemplate: payload,
        },
      };
      return setIsLoadingCreateUpdateTemplate;
    }

    case SuperAdminType.DELETE_TEMPLATE: {
      const deleteTemplate: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          templates: state.shopify.templates.filter((item) => item.tag !== payload),
        },
      };
      return deleteTemplate;
    }

    case SuperAdminType.SET_IS_LOADING_DELETE_TEMPLATE: {
      const setIsLoadingDeleteTemplate: IInitialSuperAdminState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingDeleteTemplate: {
            loading: payload.loading,
            templateTag: payload.templateTag,
          },
        },
      };
      return setIsLoadingDeleteTemplate;
    }

    default:
      return state;
  }
}
