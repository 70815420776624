// Home
export const home = '/';

// Brand
export const brandFeed = '/brand-feed';

// Dashboard
export const dashboard = '/dashboard';

// Catalog
export const catalog = '/catalog';
export const catalogProduct = `${catalog}/product`;
export const catalogAddProduct = `${catalog}/add-product`;
export const catalogDownload = `${catalog}/download`;

// Multimedia
export const multimedia = '/multimedia';
export const multimediaImages = `${multimedia}/images`;
export const multimediaVideos = `${multimedia}/videos`;
export const multimediaBanners = `${multimedia}/banners`;

// Sales
export const sales = '/sales';
export const salesHistory = `${sales}/history`;
export const salesCreate = `${sales}/create`;

// Account
export const account = '/account';
export const accountProfile = `${account}/profile`;
export const accountCompany = `${account}/company`;
export const accountContacts = `${account}/contacts`;
export const accountMyBrands = `${account}/my-brands`;
export const accountPointsOfSale = `${account}/points-of-sale`;
export const brand = `${account}/brand`;

// Config
export const settings = '/settings';
const users = 'users';
const chronos = 'chronos';
const devices = 'devices';
const touchScreen = 'touch-screen';
const system = 'system';
const meta = 'meta';
const billing = 'billing';
const support = 'support';
export const settingsUsers = `${settings}/${users}`;
export const settingsChronos = `${settings}/${chronos}`;
export const settingsDevices = `${settings}/${devices}`;
export const settingsTouchScreen = `${settings}/${touchScreen}`;
export const settingsSystemSettings = `${settings}/${system}`;
export const settingsMeta = `${settings}/${meta}`;
export const settingsMetaEditAPI = `${settingsMeta}/edit-api-connection`;
export const settingsMetaGeneralConfigurationAPI = `${settingsMeta}/general-api-configuration`;
export const settingsMetaMapFieldsConfigurationAPI = `${settingsMeta}/map-fields-configuration`;
export const settingsMetaMaps = `${settingsMeta}/maps`;
export const settingMetaEditMap = `${settingsMeta}/edit-map`;
export const settingsBilling = `${settings}/${billing}`;
export const settingsSupport = `${settings}/${support}`;

// Brands
// export const brands = '/brands'
// export const addBrands = '/add-brands'

// Authentication
export const authentication = '/authentication';
export const authenticationSignup = `${authentication}/signup`;

// Admin
export const adminHome = '/admin/home';
export const adminAPIConfiguration = '/admin/api-configuration';
export const adminMap = '/admin/map';

// Notifications
export const notifications = '/notifications';
