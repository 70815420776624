import { IupdateMapFieldBodyProps } from 'interfaces/superAdmin.interface';
import * as superAdminInterface from './responseInterfaces/superAdmin.response.interface';
import { basePath, dataBasePath } from './utils/config';

export const getClientsAPI = async (
  token: string,
  type: 'client' | 'tenant'
): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      scope: type,
      populated: true,
      country_id: '',
      search: '',
      index: 0,
      limit: 1000,
    }),
  };
  const response = await fetch(`${basePath}/superadmin/search`, params);
  const data = await response.json();
  return { response, data };
};

// Map fields
export const getMapFieldsAPI = async ({
  token,
  clientId,
}: {
  token: string;
  clientId?: string;
}): Promise<superAdminInterface.TgetMapFieldsAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: !clientId ? {} : { client_id: clientId },
      ids: false,
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/get_map_fields`, params);
  const data = await response.json();
  return { response, data };
};

export const createMapFieldAPI = async ({
  mapField,
  token,
}: superAdminInterface.TcreateMapFieldAPIProps): Promise<superAdminInterface.TcreateMapFieldAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      items: [mapField],
    }),
  };
  const response = await fetch(`${dataBasePath}/mapping/create_map_field`, params);
  const data = await response.json();
  return { response, data };
};

export const updateMapFieldAPI = async ({
  body,
  token,
}: {
  body: IupdateMapFieldBodyProps;
  token: string;
}): Promise<superAdminInterface.TupdateMapFieldAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/mapping/update_map_field`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteMapFieldAPI = async ({
  mapFieldId,
  token,
}: {
  mapFieldId: string;
  token: string;
}): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/mapping/delete_map_field?id=${mapFieldId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// Map fields templates
export const getMapFieldsTemplatesAPI = async ({
  token,
  clientId,
}: {
  token: string;
  clientId?: string;
}): Promise<superAdminInterface.IgetMapFieldsTemplatesAPI> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(clientId ? { client_id: clientId } : {}),
  };
  const response = await fetch(`${dataBasePath}/mapping/templates`, params);
  const data = await response.json();
  return { response, data };
};

export const createMapFieldTemplateAPI = async ({
  tag,
  map_field_ids,
  token,
}: {
  tag: string;
  map_field_ids: string[];
  token: string;
}): Promise<superAdminInterface.TcreateMapFieldTemplateAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tag,
      map_field_ids,
    }),
  };
  const response = await fetch(
    `${dataBasePath}/mapping/create_map_field_template`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const deleteMapFieldTemplateAPI = async ({
  templateName,
  token,
  clientId,
}: {
  templateName: string;
  token: string;
  clientId?: string;
}): Promise<any> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    !clientId
      ? `${dataBasePath}/mapping/delete_template?tag=${templateName}`
      : `${dataBasePath}/mapping/delete_template?tag=${templateName}&client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

// Queries Shopify
export const getShopifyQueriesAPI = async ({
  clientId,
  token,
}: {
  clientId?: string;
  token: string;
}): Promise<superAdminInterface.IGetShopifyQueriesAPIResponse> => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    !clientId
      ? `${dataBasePath}/shopify_connector/get_queries`
      : `${dataBasePath}/shopify_connector/get_queries?client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const createShopifyQueryAPI = async ({
  body,
  token,
}: superAdminInterface.TcreateShopifyQueryAPIProps): Promise<superAdminInterface.TcreateShopifyQueryAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/add_query`, params);
  const data = await response.json();
  return { response, data };
};

export const deleteShopifyQueryAPI = async ({
  queryId,
  token,
}: {
  queryId: string;
  token: string;
}): Promise<superAdminInterface.IdeleteShopifyQueryAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/delete_query?id=${queryId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const updateShopifyQueryAPI = async ({
  dataToUpdate,
  token,
}: superAdminInterface.IupdateShopifyQueryAPIProps): Promise<superAdminInterface.IupdateShopifyQueryAPIResponse> => {
  const params = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filter: {
        id: dataToUpdate.queryId,
      },
      update: {
        query: dataToUpdate.query,
        tag: dataToUpdate.tag,
        description: dataToUpdate.description,
        indexable: dataToUpdate.indexable,
        scope: dataToUpdate.scope,
        audience: dataToUpdate.audience,
      },
    }),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/update_query`, params);
  const data = await response.json();
  return { response, data };
};

export const testShopifyQueryAPI = async ({
  connector_id,
  query_id,
  token,
}: {
  connector_id: string;
  query_id: string;
  token: string;
}): Promise<any> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      connector_id,
      query_id,
      limit: 3,
    }),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/test_query`, params);
  const data = await response.json();
  return { response, data };
};

// Templates Shopify
export const getTemplatesAPI = async ({
  token,
  clientId,
}: {
  token: string;
  clientId?: string;
}): Promise<superAdminInterface.IgetTemplatesAPIResponse> => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      !clientId
        ? { default: true }
        : {
            client_id: clientId,
            default: false,
          }
    ),
  };
  const response = await fetch(`${dataBasePath}/shopify_connector/templates`, params);
  const data = await response.json();
  return { response, data };
};

export const createShopifyTemplateAPI = async ({
  body,
  token,
}: superAdminInterface.TcreateShopifyTemplateAPIProps): Promise<superAdminInterface.TcreateShopifyTemplateAPIResponse> => {
  const params = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  const response = await fetch(
    `${dataBasePath}/shopify_connector/add_template_query`,
    params
  );
  const data = await response.json();
  return { response, data };
};

export const deleteShopifyTemplateAPI = async ({
  templateTag,
  clientId,
  token,
}: {
  templateTag: string;
  clientId?: string;
  token: string;
}): Promise<superAdminInterface.TdeleteShopifyTemplateAPIResponse> => {
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(
    !clientId
      ? `${dataBasePath}/shopify_connector/delete_template?tag=${templateTag}`
      : `${dataBasePath}/shopify_connector/delete_template?tag=${templateTag}&client_id=${clientId}`,
    params
  );
  const data = await response.json();
  return { response, data };
};
