import React from 'react';
import * as MetaTypes from '../meta.types';
import { metaError } from 'utils/errorCodes';
import { notificationErr } from 'views/components/UI/notification';
import * as msg from 'views/components/UI/message';
import * as api from 'api/meta.api';
import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';
import {
  IShopifyQueryItem,
  IShopifyTemplateItem,
  IUpdateQueryProps,
} from 'interfaces/superAdmin.interface';
import { TupdateShopifyConnectionAPIBodyUpdateProps } from 'api/responseInterfaces/superAdmin.response.interface';
import {
  deleteShopifyQueryAPI,
  deleteShopifyTemplateAPI,
  getShopifyQueriesAPI,
  getTemplatesAPI,
  updateShopifyQueryAPI,
} from 'api/superAdmin.api';
import { NavigateFunction } from 'react-router-dom';
import { settingsMetaEditAPI } from 'router/paths';

export async function createShopifyConnectionAction({
  dispatch,
  body,
  queries,
  navigate,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: metaResponseInterface.TcreateShopifyConnectionAPIBodyProps;
  queries: IShopifyQueryItem[];
  navigate: NavigateFunction;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS, payload: true });
  try {
    const createShopifyConnectionFetch = await api.createShopifyConnectionAPI({
      body,
      token,
    });
    if (createShopifyConnectionFetch.response.status === 409) {
      msg.messageWarning(translate('action_meta_create-shopify-connection_error-409'));
      return false;
    }

    if (
      createShopifyConnectionFetch.response.status === 200 ||
      createShopifyConnectionFetch.response.status === 201
    ) {
      const testConnection = await api.testShopifyConnectionAPI({
        token,
        connectorId: createShopifyConnectionFetch.data.id,
      });
      const newConnection: metaInterface.TShopifyConnectionItem = {
        ...createShopifyConnectionFetch.data,
        type: 'shopify',
        error: testConnection.response.status !== 200,
        shopify_connector_maps:
          createShopifyConnectionFetch.data.shopify_connector_maps?.map(
            (item) => item.map
          ) ?? [],
      };
      dispatch({
        type: MetaTypes.ADD_NEW_SHOPIFY_CONNECTION,
        payload: newConnection,
      });
      msg.messageSuccess('Conexión creada con éxito');
      navigate(`${settingsMetaEditAPI}/shopify/${createShopifyConnectionFetch.data.id}`);
      return true;
    }
    msg.messageError(translate('action_meta_create-shopify-connection_error'));
    notificationErr(metaError.create_shopify_connection_002, translate);
    return false;
  } catch (err) {
    notificationErr(metaError.create_shopify_connection_001, translate);
    msg.messageError(translate('action_meta_create-shopify-connection_error'));
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_SHOPIFY_CONNECTIONS,
      payload: false,
    });
  }
}

export async function updateShopifyConnectionAction({
  dispatch,
  body,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  body: TupdateShopifyConnectionAPIBodyUpdateProps;
  connectionId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS,
    payload: true,
  });
  try {
    const updateShopifyConnectionFetch = await api.updateShopifyConnectionAPI({
      body,
      connectionId,
      token,
    });
    if (updateShopifyConnectionFetch.response.status === 200) {
      const testConnection = await api.testShopifyConnectionAPI({
        token,
        connectorId: connectionId,
      });
      const updatedConnection = {
        ...body,
        error: testConnection.response.status !== 200,
      };
      msg.messageSuccess(translate('action_meta_update-connection_success'));
      dispatch({
        type: MetaTypes.UPDATE_SHOPIFY_CONNECTION,
        payload: {
          id: connectionId,
          data: updatedConnection,
        },
      });
      return true;
    } else {
      msg.messageError(translate('action_meta_update-connection_error'));
      notificationErr(metaError.update_shopify_connection_002, translate);
      return false;
    }
  } catch (error) {
    msg.messageError(translate('action_meta_update-connection_error'));
    notificationErr(metaError.update_shopify_connection_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS,
      payload: false,
    });
  }
}

export async function deleteShopifyConnectionAction({
  dispatch,
  connectionId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectionId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION,
    payload: { loading: true, id: connectionId },
  });
  try {
    const removeShopifyConnectionFetch = await api.removeShopifyConnectionAPI({
      connectionId,
      token,
    });
    if (removeShopifyConnectionFetch.response.status === 200) {
      msg.messageSuccess(translate('action_meta_delete-connection_success'));
      dispatch({
        type: MetaTypes.DELETE_SHOPIFY_CONNECTION,
        payload: connectionId,
      });
      return true;
    } else {
      msg.messageError(translate('action_meta_delete-connection_error'));
      notificationErr(metaError.delete_shopify_connection_002, translate);
      return false;
    }
  } catch (error) {
    msg.messageError(translate('action_meta_delete-connection_error'));
    notificationErr(metaError.delete_shopify_connection_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION,
      payload: { loading: false, id: '' },
    });
  }
}

// Configuration Shopify
export async function getShopifyConfigurationAction({
  dispatch,
  connectorId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorId: string;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_CONFIGURATION, payload: true });
  try {
    const getConfigurationFetch = await api.getShopifyConnectorConfigurationAPI({
      connectorId,
      token,
    });
    if (getConfigurationFetch.response.status === 200) {
      dispatch({
        type: MetaTypes.SET_CONFIGURATION,
        payload: getConfigurationFetch.data ?? [],
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener la configuración');
    notificationErr(metaError.get_shopify_configuration_001, translate);
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_CONFIGURATION, payload: false });
  }
}

export async function copyShopifyQueriesTemplateAction({
  dispatch,
  clientId,
  template,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  clientId: string;
  template: IShopifyTemplateItem;
  token: string;
  translate: any;
}) {
  const copyTemplateBody: metaResponseInterface.TcopyShopifyTemplateAPIBody = {
    name: template.tag,
    client_id: clientId,
    tag: template.tag,
  };
  try {
    const copyTemplateFetch = await api.copyShopifyTemplateAPI({
      body: copyTemplateBody,
      token,
    });
    if (copyTemplateFetch.response.status === 405) {
      msg.messageWarning('Ya existe una plantilla con ese nombre, por favor elige otro');
      return false;
    }
    if (
      copyTemplateFetch.response.status === 200 ||
      copyTemplateFetch.response.status === 201
    ) {
      try {
        const getQueriesFetch = await getShopifyQueriesAPI({ clientId, token });
        if (getQueriesFetch.response.status === 200) {
          dispatch({
            type: MetaTypes.SET_QUERIES,
            payload: getQueriesFetch.data.data,
          });
        }
      } catch (error) {
        msg.messageError(translate('action_meta_get-queries_error'));
        notificationErr(metaError.get_shopify_queries_001, translate);
      }

      try {
        const getTemplatesFetch = await getTemplatesAPI({ clientId, token });
        if (getTemplatesFetch.response.status === 200) {
          const formattedData: IShopifyTemplateItem[] = Object.keys(
            getTemplatesFetch.data.data
          ).map((key) => {
            return {
              tag: key,
              queries: getTemplatesFetch.data.data[key].map(
                (item: { id: string }) => item.id
              ),
            };
          });
          dispatch({
            type: MetaTypes.SET_TEMPLATES,
            payload: formattedData,
          });
        }
      } catch (error) {
        msg.messageError('Error del servidor al obtener las plantillas');
        notificationErr(metaError.get_shopify_templates_001, translate);
      }
      msg.messageSuccess('Plantilla copiada con éxito');
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function addShopifyQueriesToConfigurationAction({
  dispatch,
  connectorId,
  queries,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  connectorId: string;
  queries: string[];
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION,
    payload: true,
  });
  try {
    const createUpdateConfigurationFetch = await api.createUpdateShopifyConfigurationAPI({
      connector_id: connectorId,
      queries: queries,
      token,
    });
    if (
      createUpdateConfigurationFetch.response.status === 200 ||
      createUpdateConfigurationFetch.response.status === 201 ||
      createUpdateConfigurationFetch.response.status === 202
    ) {
      msg.messageSuccess('Configuración guardada con éxito');
      dispatch({
        type: MetaTypes.CREATE_UPDATE_CONFIGURATION,
        payload: createUpdateConfigurationFetch.data.data,
      });
      return true;
    }
    msg.messageError('Error al guardar la configuración');
    notificationErr(metaError.create_update_shopify_configuration_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al guardar la configuración');
    notificationErr(metaError.create_update_shopify_configuration_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION,
      payload: false,
    });
  }
}

// Queries Shopify
export async function getShopifyQueriesAction(
  dispatch: React.Dispatch<any>,
  clientId: string,
  token: string,
  translate: any
) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_QUERIES, payload: true });
  try {
    const getQueriesFetch = await getShopifyQueriesAPI({ clientId, token });
    if (getQueriesFetch.response.status === 200) {
      dispatch({
        type: MetaTypes.SET_QUERIES,
        payload: getQueriesFetch.data.data,
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError(translate('action_meta_get-queries_error'));
    notificationErr(metaError.get_shopify_queries_001, translate);
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_QUERIES, payload: false });
  }
}

export async function selectShopifyQueryAction(
  dispatch: React.Dispatch<any>,
  query: IShopifyQueryItem
) {
  dispatch({
    type: MetaTypes.SET_QUERY_SELECTED,
    payload: query,
  });
}

// type TcreateQueryActionProps = {
//   dispatch: React.Dispatch<any>;
//   values: ICreateQueryProps;
//   token: string;
//   translate: any;
// };
// export async function createShopifyQueryAction({
//   dispatch,
//   values,
//   token,
//   translate,
// }: TcreateQueryActionProps) {
//   dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_QUERY, payload: true });
//   if (!values.scope || values.scope === '' || values.scope === undefined) {
//     delete values.scope;
//   }
//   if (!values.audience || values.audience === undefined) {
//     delete values.audience;
//   }
//   try {
//     const createQueryFetch = await createShopifyQueryAPI({
//       body: values,
//       token,
//     });
//     if (createQueryFetch.response.status === 409) {
//       msg.messageWarning('Ya existe una query con ese nombre, por favor elige otro');
//       return false;
//     }
//     if (
//       createQueryFetch.response.status === 200 ||
//       createQueryFetch.response.status === 201
//     ) {
//       msg.messageSuccess('Query creada con éxito');
//       dispatch({
//         type: MetaTypes.ADD_NEW_QUERY,
//         payload: createQueryFetch.data,
//       });
//       return true;
//     }
//     msg.messageError('Error al crear la query');
//     notificationErr(metaError.create_shopify_query_002, translate);
//     return false;
//   } catch (error) {
//     msg.messageError('Error del servidor al crear la query');
//     notificationErr(metaError.create_shopify_query_001, translate);
//     return false;
//   } finally {
//     dispatch({ type: MetaTypes.SET_IS_LOADING_CREATE_QUERY, payload: false });
//   }
// }

export async function deleteShopifyQueryAction({
  dispatch,
  clientId,
  queryId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  clientId: string;
  queryId: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_QUERY,
    payload: { loading: true, queryId },
  });
  try {
    const deleteQueryFetch = await deleteShopifyQueryAPI({
      queryId,
      token,
    });
    if (deleteQueryFetch.response.status === 200) {
      msg.messageSuccess('Query eliminada con éxito');
      dispatch({
        type: MetaTypes.DELETE_QUERY,
        payload: queryId,
      });
      try {
        const getTemplatesFetch = await getTemplatesAPI({ clientId, token });
        if (getTemplatesFetch.response.status === 200) {
          const formattedData: IShopifyTemplateItem[] = Object.keys(
            getTemplatesFetch.data.data
          ).map((key) => {
            return {
              tag: key,
              queries: getTemplatesFetch.data.data[key].map(
                (item: { id: string }) => item.id
              ),
            };
          });
          dispatch({
            type: MetaTypes.SET_TEMPLATES,
            payload: formattedData,
          });
          return true;
        }
        return false;
      } catch (error) {
        msg.messageError('Error del servidor al obtener las plantillas');
        notificationErr(metaError.get_shopify_templates_001, translate);
        return false;
      }
      // await getShopifyTemplatesAction({ dispatch, clientId, token, translate });
      // return true;
    }
    msg.messageError('Error al eliminar la query');
    notificationErr(metaError.delete_shopify_query_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar la query');
    notificationErr(metaError.delete_shopify_query_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_QUERY,
      payload: { loading: false, queryId: '' },
    });
  }
}

export async function updateShopifyQueryAction({
  dispatch,
  dataToUpdate,
  showSuccessMsg = true,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  dataToUpdate: IUpdateQueryProps;
  showSuccessMsg?: boolean;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_UPDATE_QUERY, payload: true });
  if (
    !dataToUpdate.scope ||
    dataToUpdate.scope === '' ||
    dataToUpdate.scope === undefined
  ) {
    delete dataToUpdate.scope;
  }
  if (!dataToUpdate.audience || dataToUpdate.audience === undefined) {
    delete dataToUpdate.audience;
  }
  try {
    const updateQueryFetch = await updateShopifyQueryAPI({
      dataToUpdate,
      token,
    });
    if (updateQueryFetch.response.status === 200) {
      showSuccessMsg &&
        showSuccessMsg === true &&
        msg.messageSuccess('Query actualizada con éxito');
      dispatch({
        type: MetaTypes.UPDATE_QUERY,
        payload: {
          id: dataToUpdate.queryId,
          data: {
            tag: dataToUpdate.tag,
            description: dataToUpdate.description,
            query: dataToUpdate.query,
            indexable: dataToUpdate.indexable,
            scope: dataToUpdate.scope,
          },
        },
      });
      return true;
    }
    msg.messageError('Error al actualizar la query');
    notificationErr(metaError.update_shopify_query_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al actualizar la query');
    notificationErr(metaError.update_shopify_query_001, translate);
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_UPDATE_QUERY, payload: false });
  }
}

// Templates shopify
export async function getShopifyTemplatesAction({
  dispatch,
  clientId,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  clientId: string;
  token: string;
  translate: any;
}) {
  dispatch({ type: MetaTypes.SET_IS_LOADING_TEMPLATES, payload: true });
  try {
    const getTemplatesFetch = await getTemplatesAPI({ clientId, token });
    if (getTemplatesFetch.response.status === 200) {
      const formattedData: IShopifyTemplateItem[] = Object.keys(
        getTemplatesFetch.data.data
      ).map((key) => {
        return {
          tag: key,
          queries: getTemplatesFetch.data.data[key].map(
            (item: { id: string }) => item.id
          ),
        };
      });
      dispatch({
        type: MetaTypes.SET_TEMPLATES,
        payload: formattedData,
      });
      return true;
    }
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al obtener las plantillas');
    notificationErr(metaError.get_shopify_templates_001, translate);
    return false;
  } finally {
    dispatch({ type: MetaTypes.SET_IS_LOADING_TEMPLATES, payload: false });
  }
}

export function selectShopifyTemplateAction({
  dispatch,
  template,
}: {
  dispatch: React.Dispatch<any>;
  template: IShopifyTemplateItem;
}) {
  dispatch({
    type: MetaTypes.SET_TEMPLATE_SELECTED,
    payload: template,
  });
}

// export async function createUpdateShopifyTemplateAction({
//   dispatch,
//   values,
//   token,
//   translate,
// }: {
//   dispatch: React.Dispatch<any>;
//   values: IShopifyTemplateItem;
//   token: string;
//   translate: any;
// }) {
//   dispatch({
//     type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_TEMPLATE,
//     payload: true,
//   });
//   try {
//     const createUpdateTemplateFetch = await createShopifyTemplateAPI({
//       body: values,
//       token,
//     });
//     if (createUpdateTemplateFetch.response.status === 409) {
//       messageWarning('Ya existe una plantilla con ese nombre, por favor elige otro');
//       return false;
//     }
//     if (
//       createUpdateTemplateFetch.response.status === 200 ||
//       createUpdateTemplateFetch.response.status === 201
//     ) {
//       messageSuccess('Plantilla creada con éxito');
//       dispatch({
//         type: MetaTypes.CREATE_UPDATE_TEMPLATE,
//         payload: {
//           tag: values.tag,
//           data: values,
//         },
//       });
//       return true;
//     }
//     messageError('Error al crear la plantilla');
//     notificationErr(metaError.create_update_shopify_template_002, translate);
//     return false;
//   } catch (error) {
//     messageError('Error del servidor al crear la plantilla');
//     notificationErr(metaError.create_update_shopify_template_001, translate);
//     return false;
//   } finally {
//     dispatch({
//       type: MetaTypes.SET_IS_LOADING_CREATE_UPDATE_TEMPLATE,
//       payload: false,
//     });
//   }
// }

export async function deleteShopifyTemplateAction({
  dispatch,
  clientId,
  templateTag,
  token,
  translate,
}: {
  dispatch: React.Dispatch<any>;
  clientId: string;
  templateTag: string;
  token: string;
  translate: any;
}) {
  dispatch({
    type: MetaTypes.SET_IS_LOADING_DELETE_TEMPLATE,
    payload: { loading: true, tag: templateTag },
  });
  try {
    const deleteTemplateFetch = await deleteShopifyTemplateAPI({
      templateTag,
      clientId,
      token,
    });
    if (deleteTemplateFetch.response.status === 200) {
      msg.messageSuccess('Plantilla eliminada con éxito');
      dispatch({
        type: MetaTypes.DELETE_TEMPLATE,
        payload: templateTag,
      });
      return true;
    }
    msg.messageError('Error al eliminar la plantilla');
    notificationErr(metaError.delete_shopify_template_002, translate);
    return false;
  } catch (error) {
    msg.messageError('Error del servidor al eliminar la plantilla');
    notificationErr(metaError.delete_shopify_template_001, translate);
    return false;
  } finally {
    dispatch({
      type: MetaTypes.SET_IS_LOADING_DELETE_TEMPLATE,
      payload: { loading: false, tag: '' },
    });
  }
}
